html, body {
  background: #FFF;
  margin: 0;
  padding: 0;
  color: #121212;
  font: 16px 'Segoe UI', Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 0.02em;

  @include respond-down(M) {
    font-size: 14px;
  }
}

html {
  scroll-behavior: smooth;
}

p {
  margin: 0 0 0.5em 0;
  line-height: 1.4em;
}

ul {
  line-height: 1.4em;
  padding-inline-start: 1.1em;

  li {
    margin-bottom: 0.4em;
  }
}

.decorator {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  text-align: center;

  svg {
    width: 1200px;
    margin: -100px -2000px 0 -2000px;

    path {
      fill: #eee;
    }
  }
}

.container {
  position: relative;
  z-index: 20;
  max-width: 1000px;
  margin: auto;
  padding: 0 20px;

  &.content {
    z-index: 30;
  }

  @include respond-down(M) {
  }
}

.header {
  .nav {
    border-bottom: 1px solid #d2d2d2;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-down(M) {
      margin-bottom: 30px;
    }

    .logo-text {
      font-size: 2em;
      font-weight: 400;
      padding: 4px 0;
    }

    .logo {

      .img-logo {
        height: 80px;
        margin: -14px 0;
      }
    }

    .links {
      display: flex;
      align-items: center;
      margin: 0 -10px;

      @include respond-down(S) {
        display: none;
      }

      .link {
        padding: 5px 10px;
        text-decoration: none;
        color: #111;
        font-size: 18px;
        transition: color 0.25s ease;

        @include respond-down(M) {
          font-size: 15px;
        }

        span {
          position: relative;

          &:after {
            width: 0px;
            height: 1px;
            background: #222;
            transition: width 0.35s ease;
            position: absolute;
            content: '';
            bottom: -3px;
            left: 0;
          }
        }

        &:hover, &.current {
          color: #555;

          span:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.main-content {
  padding-bottom: 20px;
  padding-top: 30px;

  @include respond-down(M) {
    padding-bottom: 0px;
  }

  section {
    padding-top: 20px;
    margin-bottom: 30px;

    .pict {
      background: #FFF;
      padding: 10px;
      border: 1px solid #bfbfbf;
      border-radius: 4px;
      box-sizing: border-box;
      width: 100%;
    }

    @include respond-down(M) {
      padding-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.screenshot {
  overflow: hidden;

  img {
    width: 100%;
    display: block;
    margin: -1px 0;
  }
}

.h1 {
  margin-top: 0;
  font-size: 2.0em;
  line-height: 1.2em;
  margin-bottom: 30px;
  font-weight: 400;

  @include respond-down(M) {
    margin-bottom: 10px;
  }
}

.h2 {
  margin-top: 0;
  font-weight: 400;
  font-size: 1.6em;
}

.article {
  font-weight: 400;
  margin-bottom: 1em;
  border-left: 5px solid #efefef;
  padding-left: 10px;

  .title {
    margin-bottom: 0.3em;
    font-size: 1.1em;
    color: #333;
  }
  .info {
    font-size: 0.9em;
  }
}

.footer {
  .bottom {
    border-top: 1px solid #eee;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #888;

    @include respond-down(M) {
      min-height: 40px;
    }
  }
}