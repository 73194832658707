.tool {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;

  .tool-name {
    background: #FFF;
    padding: 10px 16px;
    border-bottom: 1px solid #bfbfbf;
    font-weight: bold;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;

    .git {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #2846ff;
      text-decoration: none;
    }
  }

  .code {
    display: flex;

    @include respond-down(S) {
      flex-wrap: wrap;
    }

    .tree {
      flex: 0 0 300px;
      overflow: auto;
      height: 400px;
      box-sizing: border-box;

      @include respond-down(M) {
        flex: 0 0 240px;
      }
      @include respond-down(S) {
        flex: 0 0 100%;
        height: auto;
      }
    }

    .content {
      padding: 5px 8px;
      border-left: 1px solid #bfbfbf;
      overflow: hidden;
      position: relative;
      flex: 1 1 100%;
      white-space: pre;
      height: 400px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 1.3em;

      @include respond-down(S) {
        border-left: none;
        border-top: 1px solid #bfbfbf;
      }

      .btn {
        margin-bottom: 10px;
        display: inline-block;
        text-decoration: none;
        background: #fff;
        padding: 3px 6px;
        border-radius: 3px;
        border: 1px solid #aaa;

        &:hover {
          border: 1px solid #999;
        }
      }

      .wget {
        background: #fff;
        padding: 3px 8px;
        border-radius: 3px;
        font-family: monospace;
        margin: 0;
        font-size: 14px;
      }

      .bin, &:after, &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        z-index: 300;
      }

      &:before {
        height: 30px;
        background: linear-gradient(to top, #EEE 0%, transparent 100%);
      }

      &:after {
        top: 0;
      }

      &[data-file="files"]:after {
        display: none;
      }

      .bin {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        text-transform: uppercase;
        color: #888;
        top: 0;
      }
    }
  }
}
