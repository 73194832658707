.tree {
  overflow: auto;
  flex: 1 1 100%;
  padding: 4px 0 4px 10px;
  background: #FFF;

  .children {
    margin-left: 20px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -36px;
      left: -18px;
      border-left: 1px solid #999;
      width: 1px;
      bottom: 10px;
    }

    &:after {
      content: "";
      position: absolute;
      border-top: 1px solid #999;
      top: -12px;
      left: -18px;
      width: 10px;
    }
  }

  .item:last-child {
    & > .children:before {
      height: 24px;
    }
  }

  .item {
    position: relative;

    &.type-dir > .meta > .file {
      display: none;
    }
    &.type-file > .meta {
      & > .folder { display: none; }
      & > .name .arrow { opacity: 0; }
    }

    .meta {
      padding-left: 16px;
      height: 24px;
      display: flex;
      align-items: center;
      transition: background-color 0.2s ease;
      cursor: pointer;
      position: relative;
      z-index: 20;

      &:after {
        width: 10px;
        height: 1px;
        background: #888;
        position: absolute;
        left: 0;
        top: 12px;
        //content: '';
      }

      &:hover {
        .name {
          background-color: #eee;
        }
      }

      .name {
        white-space: nowrap;
        border-radius: 4px;
        padding: 3px 6px 3px 5px;
        flex: 1 1 100%;
        margin: 0 6px 0 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background 0.2s, color 0.2s;

        .arrow {
          transition: transform 0.25s ease;
          transform: rotate(-90deg);
        }
      }
      &.selected {
        .name {
          background: #2846ff;
          color: #FFF;
        }
      }

      svg {
        flex: 0 0 auto;
      }
    }

    .children > .item {
      display: none;
    }

    &.opened {
      & > .meta > .name .arrow {
        transform: rotate(90deg);
      }

      & > .children > .item {
        display: block;
      }
    }
  }
}
